import { useTranslation } from 'next-i18next';
import { forwardRef } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { signIn, signOut, useSession } from 'next-auth/client';
import Dropdown from 'react-bootstrap/Dropdown';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

export default function Topbar() {
    const [session, loading] = useSession();
    const router = useRouter();

    const { t } = useTranslation('menu');

    const AvatarToggle = forwardRef(({ onClick }, ref) => (
        <a
            href=""
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
        >
            <div className="avatar avatar-sm">
                <span className="avatar-title rounded-circle">{session.user.name.split(' ').map((n) => n[0]).join('')}</span>
            </div>
        </a>
    ));

    const navItems = [
        { name: t('apps'), path: '/apps' },
        { name: t('partners'), path: '/partners' },
        { name: t('FAQ'), path: '/faq' },
        { name: t('contact'), path: '/contact' },
    ]

    const navItemTemplate = function () {
        return navItems.map((item, index) => (
            <Link href={item.path} key={index}>
                <a className={router.pathname === item.path ? "nav-link active" : "nav-link"}>{item.name}</a>
            </Link>
        ));
    }

    return (
        <Navbar bg="white" expand="md" sticky="top">
            <div className="container">
                <div className="row flex-fill">
                    <div className="col-4 pt-1">
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="mr-auto">
                                {navItemTemplate()}
                                {/* <div className="nav-link d-sm-block d-md-none">
                                    <LanguageSwitcher />
                                </div> */}
                            </Nav>
                        </Navbar.Collapse>
                    </div>

                    <div className="col-4 text-center pt-2">
                        <Link href="/">
                            <a className="navbar-brand">
                                <Image
                                    src="/img/logo.png"
                                    alt="HYAPP"
                                    className="navbar-brand-img"
                                    width={100}
                                    height={32}
                                />
                            </a>
                        </Link>
                    </div>

                    <div className="col-4 d-flex justify-content-end pt-1">
                        {session &&
                            <div className="navbar-user d-flex align-items-start pr-3">
                                <div className="d-flex align-items-center">
                                    {/* <div className="mr-4 d-none d-md-flex">
                                        <LanguageSwitcher />
                                    </div> */}

                                    <Dropdown>
                                        <Dropdown.Toggle as={AvatarToggle} id="avatar-dropdown" />
                                        <Dropdown.Menu align="right" className="super-colors">
                                            {/* <Link> */}
                                            <a href="/account#apps" className="dropdown-item"><i className="fe fe-grid mr-1"></i> {t('my-apps')}</a>
                                            {/* </Link> */}
                                            <Link href="/account">
                                                <a className="dropdown-item"><i className="fe fe-user mr-1"></i> {t('my-account')}</a>
                                            </Link>
                                            <Dropdown.Divider />
                                            <a
                                                href={`/api/auth/signout`}
                                                className="dropdown-item"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    signOut({ callbackUrl: 'signOut' });
                                                }}
                                            ><i className="fe fe-log-out mr-1"></i> {t('logout')}</a>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        }
                        {!session &&
                            <div className="navbar-login pr-3">
                                <div className="d-flex align-items-center pt-1">
                                    {/* <div className="nav-item mr-3 d-none d-md-flex">
                                        <LanguageSwitcher />
                                    </div> */}
                                    {/* <div className="nav-item mr-2">
                                        <Link href="/register">
                                            <a className="btn btn-primary btn-sm text-nowrap">
                                                <i className="fe fe-user-plus mr-2"></i>{t('register')}
                                            </a>
                                        </Link>
                                    </div> */}
                                    <div className="nav-item">
                                        <a
                                            href={`/api/auth/signin`}
                                            className="btn btn-outline-primary btn-sm text-nowrap"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                signIn('keycloak');
                                            }}
                                        >
                                            <i className="fe fe-user mr-2"></i>{t('login')}</a>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </Navbar>
    )
};
