import Head from 'next/head';
import Topbar from './topbar';
import Footer from './footer';

export default function Layout({ children, title, classes }) {
    let contentClasses = 'main-content';

    if (classes) {
        contentClasses += ' ' + classes;
    }

    return (
        <div id="root">
            <Head>
                {title
                    ? <title>HYAPP - {title}</title>
                    : <title>HYAPP</title>
                }

                <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
                <meta name="description" content="Eine Datenplattform unbegrenzte Analysen und Benchmarks" />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="HYAPP" />
                <meta property="og:locale" content="de_DE" />
                <meta property="og:url" content="https://hyapp.de" />
                <meta property="og:image" content="https://hyapp.de/img/hyapp-preview.png" />
                <meta property="og:title" content="HYAPP – Krankenhaus Datenplattform" />
                <meta property="og:description" content="Eine Datenplattform unbegrenzte Analysen und Benchmarks" />
                <meta name="viewport" content="width=device-width, initial-scale=1"></meta>

                <link rel="icon" href="/favicon.ico" sizes="any" />
                <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
                <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
                <link rel="manifest" href="/site.webmanifest" />
            </Head>

            <Topbar />

            {/* Main Container */}
            <div className={contentClasses}>
                {children}
            </div>

            <Footer />
        </div>
    )
}
