import { useTranslation } from 'next-i18next';
import React from 'react';
import Image from 'next/image';
import Link from 'next/link';

export default function Footer() {
    return (
        <div className="footer bg-footer mt-6">
            <div className="container">
                <div className="row py-5">
                    <div className="col-3">
                        <Link href="/">
                            <a className="">
                                <Image
                                    src="/img/logo-footer.png"
                                    alt="HYAPP"
                                    className=""
                                    width={100}
                                    height={54}
                                />
                            </a>
                        </Link>
                        <div className="row mt-3">
                            <div className="col">
                                <a href="https://www.linkedin.com/company/logex-de/" target="_blank" rel="noopener">
                                    <Image
                                        src="/img/social/linkedin.png"
                                        alt="LinkedIn"
                                        className=""
                                        width={22}
                                        height={22}
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-3">
                        <h4 className="text-muted">Plattform</h4>
                        <ul className="list-unstyled list-footer">
                            <li><Link href="/"><a>
                                Startseite
                            </a></Link></li>
                            <li><Link href="/apps"><a>
                                Apps
                            </a></Link></li>
                            <li><Link href="/partners"><a>
                                Partner
                            </a></Link></li>
                        </ul>
                    </div>
                    <div className="col-3">
                        <h4 className="text-muted">Information</h4>
                        <ul className="list-unstyled list-footer">
                            <li><Link href="/faq"><a>
                                FAQ
                            </a></Link></li>
                            <li><Link href="/contact"><a>
                                Kontakt
                            </a></Link></li>
                            <li><a href="https://logex.com/de/" target="_blank" rel="noopener">
                                Über LOGEX
                            </a></li>
                        </ul>
                    </div>
                    <div className="col-3">
                        <h4 className="text-muted">Rechtliches</h4>
                        <ul className="list-unstyled list-footer">
                            <li><Link href="/privacy"><a>
                                Datenschutz
                            </a></Link></li>
                            <li><Link href="/impressum"><a>
                                Impressum
                            </a></Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
};
